
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';

    export default {
        name: 'ServerSelection',

        setup() {
            const servers = ref<Array<{ id: number, name: string }>>([{
                "id": 1,
                "name": "MySQL"
            },
            {
                "id": 2,
                "name": "SQLServer"
            },]);
            const selectedServer = ref<string | null>(null);
            const router = useRouter();

            onMounted(async () => {
                try {
                    const response = await fetch('YOUR_API_ENDPOINT_HERE');
                    servers.value = await response.json();
                } catch (error) {
                    console.error('Error fetching servers:', error);
                }
            });

            function goNext() {
                if (selectedServer.value) {
                    console.log(selectedServer.value);

                    if (selectedServer.value == "SQLServer")

                        router.push({
                            name: 'ServerDetails',
                            state: {
                                serverType: selectedServer.value,
                                host: "20.115.53.89",
                                serverName: "Seal Sql Server"}
                        });
                } else {
                    alert('Please select a server before proceeding.');
                }
            }


            return {
                servers,
                selectedServer,
                goNext
            };
        }
    }
